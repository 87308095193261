import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'

import Router from 'vue-router'
import './assets/scss/index.scss'
import routes from 'vue-auto-routing'
import store from './store'
import vuetify from './plugins/vuetify'

const router = new Router({
  routes,
})
Vue.use(Router);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import NavigationBar from './components/navigationbar.vue'

@Component({
  components: {
    NavigationBar
  }
})
export default class App extends Vue{

}

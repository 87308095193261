<template>
  <div class="navigationbar">
    <v-app-bar
      class="bg-gray-200"
      elevation="1"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <div></div>

      <v-spacer></v-spacer>

      <span>
      <h1 class="text-4xl" style="font-family: 'Brush Script MT';" >Studio Charme</h1>
      </span>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      class="align-center bg-gray-200"
      app
      width="11/12"
      height="auto"
      temporary>
      <div class="w-full">
        <v-list
          nav
        >
          <v-list-item-group
            class="text-center my-auto w-screen"
            v-model="group"
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-title @click="$router.push('/')">HOME</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title @click="$router.push('/gallery')">GALERIE</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title @click="$router.push('/about')">ÜBER MICH</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title @click="$router.push('/reviews')">REZENSIONEN</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title @click="$router.push('/prices')">PREISE</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title @click="$router.push('/contact')">KONTAKT</v-list-item-title>
            </v-list-item>
          </v-list-item-group>

        </v-list>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {Component, Vue, Watch, Prop} from 'vue-property-decorator';

@Component
export default class NavigationBar extends Vue{

  @Prop()
  drawer = false;
  @Prop() 
  group = null;

  @Watch("group")
  groupChanged() {
    if (this.group)
    {
      this.drawer = false;
    }
  }
}
</script>